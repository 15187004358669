<template>
  <v-dialog v-model="dialog" persistent max-width="900px" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">{{ industry ? 'Update' : 'Create' }} Industry</span>
      </v-card-title>
      <v-card-text>
        <Form ref="form" v-model="valid" lazy-validation @submit="create" onSubmit="return false;">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="name" label="Industry Name*" :rules="nameRules" required dense></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </Form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox v-model="createAnother" label="Create Another" dense />
        <v-btn class="ml-3 px-5" @click="cancel">Cancel</v-btn>
        <v-btn class="ml-3 px-5" color="primary" @click="create">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { rules } from '@/helpers/validation';

export default {
  name: 'CreateIndustry',
  props: {
    industry: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      name: '',
      createAnother: false,
      valid: false,
      nameRules: [rules.required, rules.moreThanTwo],
    };
  },
  created() {
    if (this.industry) {
      this.name = this.industry.name;
    }
  },
  computed: {
    ...mapState(['formDirty']),
  },
  methods: {
    ...mapActions(['createIndustry', 'updateIndustry']),
    async create() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.industry) {
        const record = await this.updateIndustry({ id: this.industry.id, name: this.name });
        if (!record) {
          return;
        }
      } else {
        const record = await this.createIndustry({ name: this.name });
        if (!record) {
          return;
        }
      }

      this.name = '';

      if (!this.createAnother) {
        this.close();
      }
      this.$refs.form.reset();
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
